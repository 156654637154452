import React from "react";
import { graphql } from "gatsby";
import Page from "../components/Layout/Page";
import Slice from "../components/Elements/Slice";
import sortBy from "lodash.sortby";
import { Query } from "..";
import ExternalLink from "../components/Elements/ExternalLink";
import pageColors from "../data/pageColors";
import pageTitles from "../data/pageTitles";
import PageHeader from "../components/Elements/PageHeader";

const Badge = ({ children }) => (
    <span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 ml-2">
        {children}
    </span>
);

const OpenSource = ({ data }: { data: Query }) => {
    const repositories = sortBy(
        data.allGithubData.nodes[0].data.nodes,
        (repository) => repository.stargazers.totalCount * -1,
    );

    return (
        <Page title={pageTitles.openSource}>
            <PageHeader
                color={pageColors.openSource}
                counts={[
                    `${repositories.length} repositories`,
                    `${data.allGithubData.nodes[0].data.viewer.pullRequests.totalCount} pull requests`,
                ]}
            >
                I've been a developer for a bunch of years now, and throughout
                my career I made a lot of open source packages for various
                things I thought might be useful to others.
                <br />
                Some are not maintained anymore but might still be useful for
                learning so you'll find a bit of everything here.
            </PageHeader>
            <div className="flex flex-wrap p-5 lg:p-10 dark:bg-gray-900">
                {repositories.map((repository) => (
                    <ExternalLink
                        key={repository.id}
                        href={repository.url}
                        className="no-underline flex xl:w-1/2 w-full"
                    >
                        <Slice className="w-full mb-5 lg:m-5 rounded overflow-hidden">
                            <header
                                className={
                                    "uppercase text-right font-bold w-full"
                                }
                            >
                                <h2
                                    className={
                                        "text-1xl lg:text-3xl m-0 font-hairline"
                                    }
                                >
                                    {repository.owner.login}
                                </h2>
                                <h1 className={"text-3xl lg:text-5xl m-0"}>
                                    {repository.name}
                                </h1>
                            </header>
                            <p className="uppercase">
                                {repository.stargazers.totalCount} ⭑
                            </p>
                            <div className={"my-5"}>
                                {repository.languages.nodes.map((language) => (
                                    <Badge>{language.name}</Badge>
                                ))}
                            </div>
                            <p className={"text-base lg:text-xl font-serif"}>
                                {repository.description}
                            </p>
                        </Slice>
                    </ExternalLink>
                ))}
            </div>
        </Page>
    );
};

export const query = graphql`
    {
        allGithubData(
            sort: {
                fields: data___nodes___stargazers___totalCount
                order: DESC
            }
        ) {
            totalCount
            nodes {
                data {
                    viewer {
                        pullRequests {
                            totalCount
                        }
                    }
                    nodes {
                        id
                        name
                        isArchived
                        description
                        url
                        owner {
                            login
                        }
                        languages {
                            nodes {
                                name
                                color
                            }
                        }
                        stargazers {
                            totalCount
                        }
                    }
                }
            }
        }
    }
`;

export default OpenSource;
